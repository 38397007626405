export const home = (data) => {
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    'name': 'SBFilms',
    'url': 'http://sbfilms.com',
    'isPartOf': {
      '@type': 'WebSite'
    },
    'publisher': {
      '@type': 'Organization',
      ...(data.seo && { 'name': `${data.seo.title}` }),
      ...(data.seo && { 'description': `${data.seo.description}` }),
      'brand': 'SBFilms',
      'email': 'pau@sbfilms.com',
      'url': 'http://sbfilms.com',
      'image': 'http://sbfilms/images/og_cover.jpg',  // required CHECK
      'contactPoint': [
        {
          '@type': 'ContactPoint',
          'email': 'pau@sbfilms.com',
          'contactType': 'Contact',
          'availableLanguage': ['English']
        }
      ],
      'logo': 'http://sbfilms/images/logo--sbfilms.png'
    }
  })
}
