import { theme }    from '../../styles/reset'

export const Favicon = () => {
  return (
    <>
      <link rel='icon' type='image/x-icon' sizes='16x16' href='/favicon/favico.ico' />
      <link rel='icon' type='image/png' sizes='16x16' href='/favicon/favicon-16x16.png' />
      <link rel='icon' type='image/png' sizes='32x32' href='/favicon/favicon-32x32.png' />
      <link rel='apple-touch-icon' sizes='180x180' href='/favicon/apple-touch-icon.png' />
      <link rel='icon' type='image/png' sizes='192x192' href='/favicon/android-icon-192x192.png' />
      <link rel='icon' type='image/png' sizes='512x512' href='/favicon/android-icon-512x512.png' />
      <link rel='manifest' href='/favicon/site.webmanifest' crossOrigin='use-credentials' />
      <link rel='mask-icon' href='/favicon/safari-pinned-tab.svg' color={theme.colors.background} />
      <meta name='apple-mobile-web-app-capable' content='yes' />
      <meta name='apple-mobile-web-app-status-bar-style' content='black' />
      <meta name='apple-mobile-web-app-title' content='Mammafiore' />
      <meta name='application-name' content='Mammafiore' />
      <meta name='theme-color' content={theme.colors.background} />
      <meta name='msapplication-navbutton-color' content={theme.colors.background} />
      <meta name='msapplication-TileColor' content={theme.colors.background} />
    </>
  )
}

